import React from "react";

function HomeFooterBanner() {
  const heading = "Ready to get started?";
  const subHeading =
    "India's Fastest Flatform for the Bill Payments. Easy to Pay.";

  return (
    <section className="get-start wow fadeInUp">
      <div className="overlay">
        <div className="container">
          <div className="col-12">
            <div className="get-content">
              <div className="section-text">
                <h3 className="title">{heading}</h3>
                <p>{subHeading}</p>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.mobileapp.payboom&hl=en_IN"
                target="_blank"
                rel="noreferrer"
                className="cmn-btn"
              >
                Download App Now
              </a>
              <img src="/images/get-start.png" alt="images" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeFooterBanner;
