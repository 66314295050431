import React from "react";

function HomeCallToAction() {
  return (
    <section className="call-action">
      <div className="overlay pt-120">
        <div className="container wow fadeInUp">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-10">
              <div className="main-content">
                <div className="section-header text-center">
                  <h2 className="title">
                    <span>
                      Refer your friends and family earn upto 500 rupees T. C.
                      apply .
                    </span>
                  </h2>
                </div>
                <div className="bottom-area text-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mobileapp.payboom&hl=en_IN"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      loading="lazy"
                      style={{
                        width: "250px",
                        display: "block",
                        margin: "auto",
                      }}
                      src="/images/play.jpg"
                      alt=""
                    />
                  </a>
                  <p>
                    <img
                      loading="lazy"
                      style={{
                        width: "250px",
                        display: "block",
                        margin: "auto",
                      }}
                      src="/images/apple.png"
                      alt=""
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeCallToAction;
