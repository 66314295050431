import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "./CustomButton";

function HeaderNav() {
  return (
    <header className="header-section">
      <div className="overlay">
        <div className="container">
          <div className="row d-flex header-area">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand" to="/">
                <img
                  src="/images/payhk.jpeg"
                  alt="logo"
                  className="img-fluid logo_imgs"
                  width="200"
                  style={{ marginTop: "-12px" }}
                />
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-content"
              >
                <i className="fas fa-bars"></i>
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbar-content"
              >
                <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to="about">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to="services">
                      Service
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="contact">
                      Contact Us
                    </Link>
                  </li>
                </ul>

                <CustomButton
                  label="Download App Now"
                  link="https://play.google.com/store/apps/details?id=com.mobileapp.payboom&hl=en_IN"
                  target="_blank"
                  className="cmn-btn"
                />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderNav;
