import React from "react";
import HomeBanner from "../components/HomeBanner";
import Faqs from "../components/Faqs";
import HomeFeatures from "../components/HomeFeatures";
import HomeCallToAction from "../components/HomeCallToAction";
import HomeFooterBanner from "../components/HomeFooterBanner";

function Home() {
  return (
    <React.Fragment>
      <HomeBanner />
      <HomeFeatures />
      <HomeCallToAction />
      <Faqs />
      <HomeFooterBanner />
    </React.Fragment>
  );
}

export default Home;
