import React from "react";

function HomeFeatures() {
  const heading = "Why should we use payboom platform?";
  const description =
    "One of the standout features of PayBoom is its attractive cashback and rewards program. Users can enjoy cashback on various transactions, such as mobile recharges, postpaid bill payments, electricity bills, DTH recharges, and more. These rewards make using PayBoom not only convenient but also financially beneficial.";
  const points = [
    "No redeem points direct cashback *",
    "Easy and real time transaction status *",
    "Up to 2.5% cashback . *",
    "Special offers available each customer . *",
  ];

  return (
    <section className="features-section mb-5">
      <div className="overlay pt-120">
        <div className="container wow fadeInUp">
          <div className="row">
            <div className="col-lg-6">
              <div className="top-section">
                <span className="head-icon d-flex justify-content-center align-items-center">
                  <img src="/images/icon/notification.png" alt="icon" />
                </span>
                <h2 className="title">{heading}</h2>
                <p>{description}</p>
                <ul className="list">
                  {points.map((features, index) => (
                    <li className="list-item d-flex align-items-center" key={index}>
                      <span className="check d-flex align-items-center justify-content-center">
                        <img src="/images/icon/check.png" alt="icon" />
                      </span>
                      <span>{features}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="img-area">
                <img src="/images/feature-item-1.png" alt="banner-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeFeatures;
