import React from "react";
import PageBanner from "../components/PageBanner";
import AboutCards from "../components/AboutCards";

function About() {
  const heading = "Your Trusted Partner for Secure and Rewarding Transactions";
  const subHeading =
    "Welcome to Payboom, your trusted partner for secure and fast mobile recharges and bill payments. At Payboom, we believe in making your life simpler and more rewarding by offering a seamless and user-friendly platform for all your recharge and bill payment needs.";
  const cardPoints = [
    {
      title: "Our Mission",
      body: "To be a market leader in offering App and service platform to general public. Payboom is an initiative to provide consumers a wide range of online payment solutions and physical cash points at doorstep for their convenience.",
      link: {},
    },
    {
      title: "Our Vision",
      body: "To be largest digital service platform for digital india. Digital india ka naya App.",
      link: {},
    },
    {
      title: "Strategic Alliance",
      body: "Payboom was established with a vision to serve the customer segment who are away from banking services in partnership with established bank and other service provider. Payboom tied-up with below banking and corporate partners. For More Information visit Our site",
      link: {
        url: "https://payboombiz.com/",
        text: "Payboombiz",
      },
    },
  ];
  return (
    <React.Fragment>
      <PageBanner
        heading="About Us"
        className="about"
        parentComponent="Home"
        parentPath="/"
      />

      <section className="about-section">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="text-area">
                  <h5 className="sub-title">About Us</h5>
                  <h2 className="title">{heading} </h2>
                  <p>{subHeading}</p>
                </div>
              </div>
              <div className="col-lg-6 text-end">
                <div className="img-area">
                  <img
                    className="img-1"
                    src="/images/account-banner.png"
                    alt="banner-image"
                    style={{ maxWidth: "80%" }}
                    
                  />
                </div>
              </div>

              <div className="row cus-mar" style={{marginTop:"100px"}}>
                {cardPoints.map((item, index) => (
                  <div className="col-xl-4 col-md-4" key={index}>
                    <AboutCards data={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default About;
