import React from "react";
import CustomButton from './CustomButton'

function HomeBanner() {
  const heading = "Secure & Fast Mobile Recharge & Bill payments!";
  const subHeading = "Simple. Transparent. Secure";
  const description =
    "No processing fees , no hidden charges. Recharge your family members mobile and earn Unlimited cashback , because every Recharge cashback. *Cashback ka dusara naam PAYBOOM *";

  return (
    <section className="banner-section">
      <div className="overlay">
        <div className="banner-content d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-start">
              <div className="col-lg-7 col-md-10">
                <div className="main-content">
                  <div className="top-area section-text justify-content-center">
                    <h4 className="sub-title">{subHeading}</h4>
                    <h1 className="title">{heading}</h1>
                    <p className="xlr">{description}</p>
                  </div>
                  <div className="bottom-area">
                    <CustomButton
                      label="Download App Now"
                      link="https://play.google.com/store/apps/details?id=com.mobileapp.payboom&hl=en_IN"
                      target="_blank"
                      className="cmn-btn"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeBanner;
