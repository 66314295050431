import React from "react";
import { Link } from "react-router-dom";

function PageBanner({ heading, className, parentComponent, parentPath }) {
  return (
    <section className={`banner-section inner-banner ${className}`}>
      <div className="overlay">
        <div className="banner-content d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-start">
              <div className="col-lg-7 col-md-10">
                <div className="main-content">
                  <h1>{heading}</h1>
                  <div className="breadcrumb-area">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb d-flex align-items-center">
                        <li className="breadcrumb-item">
                          <Link to={parentPath}>{parentComponent}</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          {heading}
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageBanner;
