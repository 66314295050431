import React from "react";

function AboutCards({ data }) {
  const { title, body, link } = data;
  return (
    <div className="count-content text-center">
      <div className="count-number ">
        <h4 className="static">{title}</h4>
      </div>
      <p style={{ textAlign: "justify" }}>
        {body}{" "}
        {link.url && (
          <a href={link.url} className="text-dark">
            {link.text}
          </a>
        )}
      </p>
    </div>
  );
}

export default AboutCards;
