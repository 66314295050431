import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "./CustomButton";

function Footer() {
  return (
    <div className="footer-section">
      <div className="container pt-120">
        <div className="row cus-mar pt-120 pb-120 justify-content-between wow fadeInUp">
          <div className="col-xl-3 col-lg-3 col-md-4 col-6">
            <div className="footer-box">
              <Link to="/" className="logo" style={{ width: "270px" }}>
                <img src="/images/payhk-footer.png" alt="logo" />
              </Link>
              <p>India's Fastest Platform for Bill Payments. Easy to Pay.</p>
              <div className="social-link d-flex align-items-center">
                <Link>
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link>
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link>
                  <i className="fab fa-linkedin-in"></i>
                </Link>
                <Link>
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-6">
            <div className="footer-box">
              <h5>Company</h5>
              <ul className="footer-link">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="about">About Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-6">
            <div className="footer-box">
              <h5>Useful Links</h5>
              <ul className="footer-link">
                <li>
                  <Link to="services">Service</Link>
                </li>
                <li>
                  <Link to="contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-6">
            <div className="footer-box">
              <h5>Support</h5>
              <ul className="footer-link">
                <li>
                  <a href="mailto:support@payboom.in">support@payboom.in</a>
                </li>
                <li>
                  <a href="tel:+919764568209">+919764568209</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-8">
            <div className="footer-box">
              <h5>Subscribe</h5>
              <form>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Enter Your Email address"
                    required
                  />

                  <CustomButton
                    label="Subscribe"
                    onCLick={() => {}}
                    className="cmn-btn"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="footer-bottom">
              <div className="left">
                <p>
                  <Link to="/policy/cancel">Cancellation Policy</Link>
                </p>
                <p>
                  <Link to="/policy/refund">Refund Policy</Link>
                </p>
                <p>
                  <Link to="/policy/privacy">Privacy Policy</Link>
                </p>
              </div>
              <div className="right">
                <p>
                  Copyright © <Link to="/">payboom</Link> | Designed by{" "}
                  <a
                    href="https://payboombiz.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kamlai multi services india Pvt. Ltd. All rights reserved.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="img-area">
        <img
          src="/images/footer-Illu-left.png"
          className="left"
          alt="Footer Left"
        />
        <img
          src="/images/footer-Illu-right.png"
          className="right"
          alt="Footer Right"
        />
      </div>
    </div>
  );
}

export default Footer;
