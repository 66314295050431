import React from "react";

const CustomButton = ({ label, onClick, link, target = "_blank", className }) => {
  return link ? (
    <a href={link} target={target} className={`custom-button ${className}`}>
      {label}
    </a>
  ) : (
    <button onClick={onClick} className={`custom-button ${className}`}>
      {label}
    </button>
  );
};

export default CustomButton;
