import React from "react";

function Faqs() {
  const heading = "Payboom FAQ";
  const subHeading = "If you have question,we have answer";
  const points = [
    {
      que: "What is Payboom?",
      ans: "Payboom is a secure and fast platform for mobile recharges and bill payments. We offer unlimited cashback on every recharge and bill payment with no processing fees or hidden charges.",
    },
    {
      que: " Can I recharge mobile numbers of my family members?",
      ans: "Yes, you can recharge the mobile numbers of your family members using Payboom. You will also earn cashback on every recharge you make.",
    },
    {
      que: "Are there any processing fees for recharges and bill payments?",
      ans: "No, Payboom does not charge any processing fees or hidden charges for recharges and bill payments.",
    },
    {
      que: "How secure is Payboom?",
      ans: "Payboom uses advanced security measures to ensure that your transactions and personal information are secure.",
    },
    {
      que: "How can I contact Payboom customer support?",
      ans: "You can contact Payboom customer support through the app or website's Contact Us section, or by emailing support@payboom.in.",
    },
    {
      que: "How do I use my earned cashback?",
      ans: "Your earned cashback will be credited to your Payboom wallet. You can use it for future recharges, bill payments, or other transactions on Payboom.",
    },
  ];

  return (
    <section className="faqs-section">
      <div className="overlay pt-120 pb-120">
        <div className="container wow fadeInUp">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="section-header text-center">
                <h5 className="sub-title">{subHeading}</h5>
                <h1>{heading}</h1>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-xl-8">
              <div className="faq-box wow fadeInUp">
                <div className="accordion" id="faqAccordion">
                  {points.map((faq, index) => (
                    <div className="accordion-item" key={index}>
                      <h5 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#test"
                          aria-expanded="false"
                          aria-controls="test"
                        >
                          {faq.que}
                        </button>
                      </h5>
                      <div
                        id="test"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#faqAccordion"
                      >
                        <div className="accordion-body">
                          <p>{faq.ans}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
